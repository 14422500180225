import { Controller } from 'stimulus'
import TextareaMarkdown from 'textarea-markdown'

// Connects to data-controller="textarea-with-image"
export default class extends Controller {
  static targets = ['textarea']

  connect() {
    const token = document.querySelector('meta[name="csrf-token"]').content

    new TextareaMarkdown(this.textareaTarget, {
      endPoint: '/photos.json',
      paramName: 'photo[image]',
      responseKey: 'image_url',
      csrfToken: token,
      placeholder: 'uploading %filename ...',
      imageableExtensions: ['jpeg', 'jpg', 'png', 'gif', 'webp']
    })
  }
}
