import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['links', 'template']

  connect() {
    this.wrapperClass = 'nested-fields'
  }

  add_plan(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(
      /NEW_PLAN_RECORD/g,
      new Date().getTime()
    )
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  add_shipping_schedule(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(
      /NEW_SHIPPING_SCHEDULE_RECORD/g,
      new Date().getTime()
    )
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest('.' + this.wrapperClass)

    // 新しいRecordの場合は単純に削除
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      // 既存レコードは非表示にして、削除フラグを建てる
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
