import { Controller } from 'stimulus'
import { plyrInstance } from '../plyr'

export default class extends Controller {
  static targets = ['video']

  connect() {
    const player = plyrInstance(this.videoTarget)
  }
}
