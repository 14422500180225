import { Controller } from 'stimulus'
import { Dashboard, ThumbnailGenerator } from 'uppy'
import { uppyInstance, uploadedFileData } from '../uppy'

export default class extends Controller {
  static targets = ['input', 'result', 'preview']
  static values = { types: Array }

  connect() {
    this.inputTarget.classList.add('hidden')
    this.uppy = this.createUppy()
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: this.typesValue,
      max: 1
    })
      .use(Dashboard, {
        trigger: this.inputTarget.parentNode,
        closeModalOnClickOutside: true,
        showRemoveButtonAfterComplete: true,
        closeAfterFinish: true
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 300
      })

    uppy.on('upload-success', (file, response) => {
      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      this.resultTarget.value = uploadedFileData(file, response)
    })

    uppy.on('thumbnail:generated', (file, preview) => {
      this.previewTarget.src = preview
    })

    return uppy
  }
}
