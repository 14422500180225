import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = [
    'price',
    'kind',
    'salesAmount',
    'systemCommissionRatio',
    'systemCommission',
    'stripeCommission'
  ]

  connect() {
    this.productKindSelectElement = document.querySelector('.js-product-kind-select')
    this.productKindSelectElement.addEventListener('change', this.calc_amount.bind(this))

    this.calc_amount()
  }

  calc_amount() {
    const productKind = this.productKindSelectElement.value
    const price = this.priceTarget.value
    const kind = this.kindTarget.getAttribute('data-calc-plan-sales-amount-kind-value')

    const query = new URLSearchParams({ price, kind, productKind })

    fetch(`/api/order_plans/calc_plan_sales_amount?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((json) => {
        this.salesAmountTarget.innerText = json.sales_amount
        this.systemCommissionRatioTarget.innerText = json.system_commission_ratio
        this.systemCommissionTarget.innerText = json.system_commission
        this.stripeCommissionTarget.innerText = json.stripe_commission
      })
  }
}
