import { Controller } from 'stimulus'
import { splideInstance } from '../splide'

// Connects to data-controller="splide"
export default class extends Controller {
  static targets = ['photos', 'photoPulse']
  static values = { options: Object }

  connect() {
    console.log(this.photosTarget)
    console.log(this.optionsValue)

    splideInstance(this.photosTarget, this.optionsValue)

    if (this.hasPhotoPulseTarget) {
      this.photoPulseTarget.classList.add('hidden')
    }

    this.photosTarget.classList.remove('hidden')
  }
}
