import Japanese from '@uppy/locales/lib/ja_JP'
import { Core, AwsS3 } from 'uppy'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export function uppyInstance({ id, types, max }) {
  const uppy = new Core({
    id: id,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: types,
      maxNumberOfFiles: max
    },
    locale: Japanese
  })

  uppy.use(AwsS3, {
    companionUrl: '/' // will call Shrine's presign endpoint mounted on `/s3/params`
  })

  return uppy
}

export function uploadedFileData(file, response) {
  const id = file.meta['key'].match(/^image_cache\/(.+)/)[1] // object key without prefix

  return JSON.stringify(fileData(file, id))
}

// constructs uploaded file data in the format that Shrine expects
function fileData(file, id) {
  return {
    id: id,
    storage: 'image_cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type
    }
  }
}
