import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.calc_amount()
  }

  static targets = [
    'price',
    'kind',
    'salesAmount',
    'systemCommission',
    'stripeCommission'
  ]

  calc_amount() {
    const price = this.priceTarget.value
    const kind = this.kindTarget.value
    const query = new URLSearchParams({ price, kind })

    fetch(`/api/order_plans/calc_sales_amount?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((json) => {
        this.salesAmountTarget.innerText = json.sales_amount
        this.systemCommissionTarget.innerText = json.system_commission
        this.stripeCommissionTarget.innerText = json.stripe_commission
      })
  }
}
