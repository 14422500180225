import { Controller } from 'stimulus' // Connects to data-controller="character-counter"

// Connects to data-controller="character-counter"
export default class extends Controller {
  static targets = ['name', 'counter']

  connect() {
    this.counterTarget.innerText = this.nameTarget.maxLength
  }

  countRemainingCharacters() {
    this.counterTarget.innerText =
      this.nameTarget.maxLength - this.nameTarget.value.length
  }
}
