import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Clipboard from 'stimulus-clipboard'
import Dropdown from 'stimulus-dropdown'
import Notification from 'stimulus-notification'
import ReadMore from 'stimulus-read-more'
import Reveal from 'stimulus-reveal-controller'
import { Tabs } from 'tailwindcss-stimulus-components'

const application = Application.start()
application.register('reveal', Reveal)
application.register('notification', Notification)
application.register('clipboard', Clipboard)
application.register('dropdown', Dropdown)
application.register('tabs', Tabs)
application.register('read-more', ReadMore)

const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// application.debug = true
